// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/brydon.mccluskey/Desktop/projects/cv-metal-fabrication/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fencing-flashing-index-js": () => import("/Users/brydon.mccluskey/Desktop/projects/cv-metal-fabrication/src/pages/fencing-flashing/index.js" /* webpackChunkName: "component---src-pages-fencing-flashing-index-js" */),
  "component---src-pages-index-js": () => import("/Users/brydon.mccluskey/Desktop/projects/cv-metal-fabrication/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interior-exterior-cladding-index-js": () => import("/Users/brydon.mccluskey/Desktop/projects/cv-metal-fabrication/src/pages/interior-exterior-cladding/index.js" /* webpackChunkName: "component---src-pages-interior-exterior-cladding-index-js" */),
  "component---src-pages-metal-roofing-index-js": () => import("/Users/brydon.mccluskey/Desktop/projects/cv-metal-fabrication/src/pages/metal-roofing/index.js" /* webpackChunkName: "component---src-pages-metal-roofing-index-js" */)
}

